/* Provide sufficient contrast against white background */
/* a {
  color: #0366d6;
} */

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.center {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
}

.text-theme {
  color: #496F53;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

h1 {
  font-size: calc(1rem * 28 / 16);
  font-weight: bold;
  text-align: center;
}

/* .main-title-container {
  size: 10vwmax;
} */

.home-welcome-text {
  text-shadow: 1px 1px 1px #496F53,
    1px 2px 1px #496F53,
    1px 3px 1px #496F53,
    1px 4px 1px #496F53,
    1px 5px 1px #496F53,
    1px 6px 1px #496F53,
    1px 18px 6px rgba(16, 16, 16, 0.4),
    1px 22px 10px rgba(16, 16, 16, 0.2),
    1px 25px 35px rgba(16, 16, 16, 0.2),
    1px 30px 60px rgba(16, 16, 16, 0.4);
}

.h1-big {
  font-size: 3vw;
  color: #fff;
  /* font-size: calc(1rem * 48 / 16); */
  font-weight: bold;
  text-align: center;
}

@media (max-width: 768px) {
  .h1-big {
    font-size: 5vw;
  }
}

.title-white {
  color: #fff;
}

.text-container1 {
  padding: 3%;
  /* font-size: 1vw; */
  font-weight: 600;
  text-align: center;
}

/* .panel-1item-row {

} */

.height-70 {
  max-height: 50%;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-btm-20 {
  margin-bottom: 20px;
}

.margin-btm-40 {
  margin-bottom: 40px;
}

.panel-4item-row {
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 2rem;  
  /* grid-template-areas:
    ". a a ."
    ". a a ."; */
}

@media (max-width: 768px) {
  .panel-4item-row {
    padding: 1rem;
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: 2rem;
  }
}

.panel-2item-row {
  padding: 0 20% 0 20%;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 2rem;
}

.panel-type2 {
  padding-left: 25px;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 50px;
  list-style-type: none;
}

.panel-type1 {
  padding: 40px 10px 40px 10px;
  align-self: center;
  text-align: -webkit-center;
}

.text-justify {
  text-align: justify;
}

/* .product-panel-size {
  max-width: calc(1rem * 1300 / 16);
}

.vision-panel-size {
  max-width: calc(1rem * 1000 / 16);
}

.team-panel-size {
  max-width: calc(1rem * 1400 / 16);
} */

.service-panel-size {
  max-width: calc(1rem * 1000 / 16);
  align-self: center;
}

.service-box-panel {
  width: 100%;
  flex-wrap: wrap;
  padding: calc(1rem * 10 / 16);
  font-size: calc(1rem * 16 / 16);
  line-height: 1.6rem;
}

.service-box {
  position: relative;
  flex-wrap: wrap;
  align-content: flex-start;
  color: #496F53;
}

/* .service-box-panel {
  width: 100%;
  flex-wrap: wrap;
  padding: 10px;
  max-width: 1000px;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  line-height: 1.6;
} */

.box-shadow1 h2,
.service-box h2 {
  /* font-family: "Poppins", Sans-serif; */
  font-size: calc(1rem * 20 / 16);
  font-weight: bold;
  text-align: center;
}


.service-box p {
  /* font-size: 14px; */
  text-align: center;
}

.image-container1 {
  padding: calc(1rem * 10 / 16);
  /* width: 2vw; */
}

/* @media (max-width: 768px) {
  .image-container1 {
    padding: calc(1rem * 10 / 16);
  }
} */

/* .image-container2 {
  width: 10px;
  height: 10px;
} */

.pad20 {
  padding: calc(1rem * 20 / 16);
}

.pad10 {
  padding: calc(1rem * 10 / 16);
}

.pad5 {
  padding: calc(1rem * 5 / 16);
}

.row-2items {
  /* width: 50%; */
  width: calc(1rem * (400 / 16));
}

.row-4items {
  /* width: 25%; */
  width: calc(1rem * (300 / 16));
}

.p1 {
  font-size: calc(1rem * 15 / 16);
  font-weight: 600;
}

.p4 {
  font-size: calc(1rem * 12 / 16);
  /* font-weight: 600; */
}

/* .insta-icon {
  margin-left: 60%;
}

.fb-icon {
  margin-right: 60%;
} */

.box-shadow1 {
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 0px rgba(73, 111, 83, 0.7);
}

.tight-box-shadow {
  width: min-content;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(73, 111, 83, 0.5);
  /* box-shadow: 0 .50rem 2rem rgba(73, 111, 83, 0.5); */
}

.product-box-shadow {
  width: 280px;
  height: 290px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(73, 111, 83, 0.5);
  z-index: 2;
}

/* .product-box-shadow img {
  width: 60%;
  height: 60%;
} */

.product-button {
  background-color: seagreen;
  color: white;
  border-radius: 5px;
  border: none;
  padding: 10px;
}

.left-right-padding-2,
.left-right-padding-2 li {
  padding: 0px 2px 0px 2px;
}

.social-icon-div {
  width: 2rem;
}

.social-icon {
  /* font-size:var(--icon-size,25px); */
  /* line-height:var(--icon-size,25px); */
  width: calc(var(--icon-size, 12px) + (2 * var(--icon-padding, .5em)));
  height: calc(var(--icon-size, 12px) + (2 * var(--icon-padding, .5em)));
}

.social-icon-footer {
  /* font-size:var(--icon-size,25px); */
  /* line-height:var(--icon-size,25px); */
  width: calc(var(--icon-size, 12px) + (2 * var(--icon-padding, .5em)));
  height: calc(var(--icon-size, 12px) + (2 * var(--icon-padding, .5em)));
}

.swiper-button-prev {
  font-weight: bold;
}

.swiper-button-next {
  font-weight: bold;
}

/* Products */

.product-tabs .nav {
  background-color: #496F53;
  color: white;
  --bs-nav-link-color: white;
  --bs-nav-link-hover-color: rgb(230, 223, 223);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  z-index: 2;
  position: relative;
}

.product-details-container h1 {
  padding: 20px;
}

.text-onleft {
  text-align: left;
}

.product-specs,
.product-specs td {
  border: none;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
}

.product-swiper-container {
  /* height: 300px; */
  width: 70vw;
  align-self: center;
}

.product-details-row {
  padding: 0 20px 0 20px;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 2rem;
}

.products-panel-row {
  padding: 0 20% 0 20%;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 2rem;
}

.footer-style1 {
  z-index: 2;
  position: relative;
}

.footer-container-style1 {
  margin-top: 50px;
}

.contact-us-style1 {
  margin-bottom: -40px;
  width: auto;
  height: 550px;
}

.about-us-style1 {
  margin-bottom: -40px;
  width: auto;
  height: 700px;
}

@media (max-width: 768px) {
  .product-swiper-container {
    /* height: 300px; */
    width: 100vw;
  }

  .product-details-row {
    padding: 0 20px 0 20px;
    display: grid;
    grid-template-columns: repeat(1, auto);
    grid-gap: 2rem;
  }

  .product-details-row img {
    width: 80%;
  }

  .products-panel-row {
    padding: 0 20% 0 20%;
    display: grid;
    grid-template-columns: repeat(1, auto);
    grid-gap: 2rem;
  }

  .contact-us-style1 {
    margin-bottom: -40px;
    width: auto;
    height: 950px;
  }

  .about-us-style1 {
    margin-bottom: -40px;
    width: auto;
    height: 1200px;
  }
}