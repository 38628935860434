  .did-floating-label-content { 
    position:relative; 
    margin-bottom:20px; 
  }
  .did-floating-label {
    color:#6b6a69; 
    font-size:15px;
    font-weight:normal;
    position:absolute;
    pointer-events:none;
    left:15px;
    top:11px;
    padding:0 5px;
    background:rgba(0, 0, 0, 0);
    transition:0.2s ease all; 
    -moz-transition:0.2s ease all; 
    -webkit-transition:0.2s ease all;
  }
  .did-floating-input {
    font-size:15px;
    display:block;
    width:100%;
    height:36px;
    padding: 0 20px;
    background: rgba(0, 0, 0, 0);
    color: #323840;
    border: 2px solid #B6B6B4;
    border-radius: 4px;
    box-sizing: border-box;
  }
  .did-floating-input:focus{
    outline:none;    
    border: 2px solid #1241ce;
    ~ .did-floating-label{
      top:-9px;
      font-size:12px;
      background:#8e97b1;
      color:#6b6a69;
    }
  }
  
  .did-floating-input:not(:placeholder-shown) ~ .did-floating-label {
    top:-8px;
    font-size:13px;
  }
  /* .did-floating-select:not([value=""]):valid ~ .did-floating-label {
    top:-8px;
    font-size:13px;
  }
  .did-floating-select[value=""]:focus ~ .did-floating-label {
    top:11px;
    font-size:13px;
  }
  .did-floating-select:not([multiple]):not([size]) {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%23003d71'/%3E%3C/svg%3E%0A");
      background-position: right 15px top 50%;
      background-repeat: no-repeat;
  } */
  
  /* .did-error-input {
      .did-floating-input {
          border: 2px solid #9d3b3b;
          color: #9d3b3b;
      }

      .did-floating-label {
          font-weight: 600;
          color: #9d3b3b;
      }

      .did-floating-select:not([multiple]):not([size]) {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%239d3b3b'/%3E%3C/svg%3E%0A");
      }
  } */
  