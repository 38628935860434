a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

.header-item {
  /* background-color: #496F53; */
  background-color: #0c2e04;
}

html {
  font-size: calc(1rem * 14 / 16);
}
@media (min-width: 768px) {
  html {
    font-size: calc(1rem * 16 / 16);
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.at-top {
  /* z-index: 2;
  position: absolute; */
  float: right;
  overflow: visible;
  position: absolute;
  z-index: 2;
  width: 100%;
  background-color:transparent;
}

.at-back {
  display: inline;
  position: relative;
  z-index: 0;
}
/* 
.position-top {
  margin-top: 0;
} */

.nav-text {
  color: white;
  font-family:Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1rem;
  font-weight: 600;
}