/*Desktop View*/
.header {
	/* position:absolute; */
	width: -webkit-fill-available;
	display: flex;
	/* background: linear-gradient(#6616fc, #f394d3); */
	justify-content: space-between;
	align-items: center;
	padding: 0px 40px;
	height: 80px;
	/* z-index: 2; */
}

.logo-nav {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
}

.logo-container {
	display: flex;
	align-items: center;
	justify-content: center;
}

.logo-container img {
	width: 80px;
	height: 80px;
}

.logo {
	width: 45px;
	height: 45px;
}

.nav-options {
	color: white;
	padding-left: 25px;
	display: grid;
	grid-template-columns: repeat(7, auto);
	grid-gap: 50px;
	list-style-type: none;
	/* z-index: 2; */
	font-weight: 600;
	text-shadow: 1px 1px 2px black, 0 0 1em black;
}

.mobile-option {
	display: none;
}

a {
	color: white;
}

.nav-selected-text {
	color: orange;
}

/* .nav-drop-down {
	display: flex;
	flex-direction: column;
  } */
.nav-drop-down-item {
	position: relative;
	display: flex;
	flex-direction: column;
	background-color: seagreen;
	justify-content: flex-start;
}

@media (min-width: 1200px) {
	.logo-nav {
		width: 80%;
	}
}

.option :hover {
	color: rgb(206, 167, 167);
	/* text-shadow: 1rem; */
}

.mobile-menu {
	display: none;
}

@media (max-width: 1000px) {

	/*Mobile View */
	.header {
		padding: 0 2rem;
	}

	.logo {
		width: 3rem;
		height: 3.5rem;
	}

	.nav-options {
		display: flex;
		width: 100%;
		height: 350px;
		position: absolute;
		top: 80px;
		left: 100%;
		opacity: 0;
		/* transition: all 0.5s ease; */
		flex-direction: column;
		list-style-type: none;
		grid-gap: 0px;
		/* z-index: 2; */
	}

	.nav-options.active {
		/* background: white; */
		left: 0%;
		opacity: 1;
		transition: all 0.5s ease;
		z-index: 5;
		align-content: flex-end;
		flex-wrap: wrap;
		padding-left: 0px;
	}

	/* .nav-options {
	  display: flex;
	  width: 100%;
	  height: 350px;
	  position: absolute;
	  top: 80px;
	  left: -100%;
	  opacity: 0;
	  transition: all 0.5s ease;
	  flex-direction: column;
	  list-style-type: none;
	  grid-gap: 0px;
	  z-index: 2;
	}
	.nav-options.active {
	  background: white;
	  left: 0;
	  opacity: 1;
	  transition: all 0.5s ease;
	  z-index: 2;
	  align-content: center;
	  padding-left: 0px;
	} */

	.menu-icon {
		width: 45px;
		height: 45px;
		color: azure;
	}

	.option {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 40px;
		width: 100px;
		background-color: seagreen;
		/* padding: 30px 0px; */
	}

	.border-outset {
		border: outset;
	}

	.border-inset {
		border: inset;
	}
}

.mobile-menu {
	display: block;
}