.div1 {
  width: 100%;
  height: 75vh;
}

.div1 .swiper {
  width: 100%;
  height: 100%;
}

.div1 .swiper-slide {
  text-align: center;
  font-size: calc(1rem * 18 / 16);
  background: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
}

.div1 .swiper-slide img {
  display: block;
  width: 100vw;
  height: 50rem;
}

.div1 .swiper {
  margin-left: auto;
  margin-right: auto;
}