.product-image-swiper .swiper {
  /* width: 60%; */
  /* height: 200px; */
  padding-top: 50px;
  padding-bottom: 50px;
}

.product-image-swiper .swiper-slide {
  background-position: center;
  background-size: cover;
  /* width: 300px;
  height: 300px; */
}

.product-image-swiper .swiper-slide img {
  display: block;
  width: 100%;
  border-radius: 20px;
  aspect-ratio: 4/3;
}

@media (max-width: 768px) {
  .product-image-swiper .swiper {
    width: 90%;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .product-image-swiper .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
}